<template>
  <div class="float-input-container" :class="isBreadcrumb ? 'position-top' : ''" >
    <slot name="before-input"></slot>
    <b-input-group
      size="md"
      class="mb-2 search-input"
      v-if="hideInput === false"
    >
      <b-form-input
        :value="value"
        @input="$emit('change', $event)"
        @keyup.enter="$emit('keyup')"
        type="search"
        :placeholder="placeholder"
        :formatter="limitSearch"
      ></b-form-input>
      <b-input-group-append
        is-text
        class="input-group-append"
        @click="$emit('click')"
      >
        <feather-icon :icon="icon" :size="iconSize" />
      </b-input-group-append>
    </b-input-group>
    <slot name="after-input"></slot>
  </div>
</template>

<script>
import { BFormInput, BInputGroupAppend, BInputGroup } from "bootstrap-vue";

export default {
  name: "FloatInput",
  components: {
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
  },
  props: {
    isBreadcrumb: {
      type: Boolean,
      default: false,
    },
    hideInput: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "SearchIcon",
    },
    iconSize: {
      type: String,
      default: "18",
    },
    value: {
      type: String,
      default: "",
    },
    limit: {
      type: Number,
      default: 20,
    },
  },
  data() {
    return {};
  },
  methods: {
    limitSearch(e) {
      return String(e).substring(0, this.limit);
    },
  },
};
</script>

<style lang="scss" scoped>
.float-input-container {
  position: absolute;
  right: 28px;
  top: 30px;
  display: flex;
}
.position-top {
  top: 75px;
}
.search-input {
  width: 300px;
  margin-left: 30px;
}
</style>
